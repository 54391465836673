.partner {
  .item {
    width: 33.33%;
    float: left;
    margin: 2.5% auto;
    .bg {
      background: rgba($graylight,.28);
      width: calc(100% - 10%);
      padding-top: 70%;
      margin: auto;
      position: relative;
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        padding: 30px;
      }
    }
    &:nth-child(3n+1) {
      .bg {
        margin-left: 0;
      }
    }
    &:nth-child(3n+3) {
      .bg {
        margin-right: 0;
      }
    }
  }
}