/*  ==========================================================================
    MEDIA QUERIES
    Collection of media queries.
    ========================================================================== */

/*
 * main steps
 */

// horizontal
$mq__desktop--large: "(min-width: 1921px)";
$mq__desktop--medium: "(max-width: 1920px)";
$mq__desktop--small: "(max-width: 1600px)";
$mq__laptop: "(max-width: 1366px)";
$mq__tablet: "(max-width: 1024px)";
$mq__phablet: "(max-width: 768px)";
$mq__phone: "(max-width: 480px)";
$mq__950: "(max-width: 1000px)";

/*
 * intermediate steps
 */

// fixed
$mq__max-h--900: "(max-height: 900px)";
$mq__max-h--800: "(max-height: 800px)";
$mq__max-h--700: "(max-height: 700px)";
$mq__max-h--600: "(max-height: 600px)";
$mq__max-h--370: "(max-height: 370px)";
$mq__max-w--300: "(max-width: 300px)";

// custom
$mq__max-w--600: "(max-width: 600px)";
$mq__max-w--800: "(max-width: 800px)";
$mq__max-w--800: "(max-width: 900px)";
$mq__max-w--550: "(max-width: 550px)";
$mq__max-w--1125: "(max-width: 1125px)";
