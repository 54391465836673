.quicklinks {
  display: flex;
  .btn {
    min-width: 255px;
    width: 255px;
    margin: auto;
    line-height: 46px;
    border-color: $green;
    font-size: 1.8em;
    color: $white;
    background: $green;
    justify-content: space-between;
    display: block;
    &+.btn {
      margin-left: 110px;
    }
    &:hover {
      background: $white;
      color: $green;
    }
  }
}