.zitat {
  text-align: center;
  .bodytext {
    p {
      font-size: 4em;
      margin: 0;
      line-height: 1.5;
    }
  }
  .autor {
    margin-top: 20px;
    font-size: 1.4em;
    font-weight: 400;
  }
}

@media #{$mq__phablet} {
  .zitat {
    .bodytext {
      p {
        font-size: 2em;
      }
    }
  }
}