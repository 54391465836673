@import url("https://cdn.curator.io/3.0/css/curator.css");
//@import url("https://cdn.bnamic.com/smw/icomoon/style.css");
@import url("https://cdn.bnamic.com/smw/templates/v1/index.css");
.crt-popup-wrapper .crt-popup .crt-popup-header IMG {
  margin-top: 0;
  border-radius: 90px;
}
.smw.layout1 {
  .crt-load-more {
    text-align: left;
  }
}
.element.crt-feed {
  font-size: 1.6em;
  position: relative;
  margin: 0 -10px;
  padding-top: 50px;
  .crt-filter {
    position: absolute;
    right: 0;
    top: -10px;
    .crt-filter-networks,
    .crt-filter-sources {
      text-align: right;
      margin-right: 5px;
      ul {
        background-color: transparent;
        .crt-filter-label {
          display: none!important;
        }
        li {
          &.active a,
          &.active a,
          & a.active,
          & a:hover {
            color: $green;
            background-color: transparent;
            i {
              background: $gray;
            }
          }
          &:nth-child(2) a {
            font-size: 30px;
          }
          a {
            font-size: 0;
            text-transform: uppercase;
            transition-duration: .5s;
            color: rgba($gray,.5);
            padding: 0;
            i {
              font-size: 30px;
              transition-duration: .5s;
              background: $green;
              padding: 5px;
              width: 50px;
              height: 50px;
              box-sizing: border-box;
              display: inline-block;
              text-align: center;
              line-height: 41px;
              color: $white;
              &:hover {
                background: $gray;
              }
            }
          }
          &+li {
            a {
              padding-left: 15px;
            }
          }
        }
      }
    }
  }
  .crt-comments span,
  .crt-likes span {
    font-size: 18px;
    margin-right: 5px;
  }
  .crt-filter,
  .crt-load-more {
    display: none;
  }
  .crt-date {
    opacity: 1;
    font-size: 14px;
  }
  .crt-post-fullname {
    text-transform: uppercase;
    font-weight: 400;
  }
  .crt-load-more {
    a {
      text-transform: lowercase;
      border: 2px solid $graylight;
      color: $gray;
      padding: 12px 20px;
      width: 235px;
      &:hover {
        text-decoration: none;
        background: $graylight;
      }
    }
  }
  &.crt-feed-branded {
    .cssload-box-loading {
      display: none;
    }
    .crt-filter,
    .crt-load-more {
      display: block;
    }
  }
  .crt-post-v2 {
    span {
      &.crt-social-icon {
        i:before {
          transform: scale(1)!important;
        }
      }
      &.crt-social-icon {
        background: $green;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        text-align: center;
      }
    }
    .crt-post-content-text {
      text-align: inherit;
      padding-left: 50px;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 15px;
      line-height: 21px;
      background: #F1F1F1;
    }
  }
  .crt-grid-post-v2,
  .crt-post-v2 {
    .crt-social-icon i,
    .crt-social-icon i {
      color: $white;
      font-size: .75em;
    }
    .crt-post-header,
    .crt-post-header {
      background: $green;
    }
    .crt-postimageWrapper {
      padding: 5px 10px;
      background: #f1f1f1;
      margin-right: 20px;
      height: 47px;
    }
    .crt-leftBar {
      padding-top: 8px;
    }
    .crt-post-footer {
      background-color: #F1F1F1;
      border: none;
      color: $graymiddle;
      .icon-likes {
        color: $green;
      }
    }
  }
}

@media #{$mq__phablet} {
  .element.crt-feed .crt-filter {
    right: auto;
    left: 0;
    top: 0px;
    .crt-filter-networks ul {
      display: flex;
      align-items: center;
    }
    .crt-filter-networks ul li:nth-child(2) a {
      font-size: 20px;
    }
    .crt-filter-networks ul li a i {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      padding: 5px;
      width: 40px;
      height: 40px;
    }
  }
  .crt-filter {
    margin: 0px;
    padding: 0px;
  }
}

@media #{$mq__phone} {
  .element.crt-feed .crt-filter {
    top: 4px;
  }
}

