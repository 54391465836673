.window {
  .text-pic {
    align-items: start;
    .rihgt {
      width: calc(50% - 100px);
    }
  }
  .window--info {
    display: flex;
    font-size: 2em;
    line-height: 15px;
    margin: 1em 0;
    &>span {
      color: $green;
    }
  }
  .window--buttons {
    display: inline-block;
    float: right;
    padding-left: 20px;
    span {
      text-align: center;
      border: 1px solid;
      padding: 5px 10px;
      box-sizing: border-box;
      display: inline;
      cursor: pointer;
      &+span {
        margin-left: 5px;
      }
      &.act {
        background: $graylight;
      }
    }
  }
  .slide {
    display: none;
    &.act {
      display: block;
    }
  }
}
.properties {
  h2 {
    font-size: 2.3em;
    margin-bottom: 2em;
    display: inline-block;
  }
  h3 {
    font-size: 20px;
    line-height: 32px;
  }
  .bodytext {
    p {
      font-size: 1.6em;
    }
  }
  img {
    position: absolute;
    left: 0;
  }
  .item {
    padding: 0 50px;
    position: relative;
  }
  .slide.act {
    .item {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      margin-top: 3em;
      &:nth-child(1),
      &:nth-child(2) {
        margin: 0;
      }
    }
  }
}
.colors {
  .group-color {
    display: flex;
    .slick-arrow {
      top: 40px;
    }
  }
  h2 {
    font-size: 2.3em;
    margin-bottom: 1em;
    display: inline-block;
  }
  h3 {
    font-size: 2em;
    line-height: 32px;
    text-align: left;
    /*min-width: 20%;*/
  }
  .grouped-colors {
    width: 100%;
    max-width: 80%;
    .item {
      display: inline-block;
      margin: 0 20px;
      max-width: 80px;
      vertical-align: top;
    }
  }
  h4 {
    font-size: 1.6em;
    text-align: center;
    font-weight: normal;
    line-height: 1.2;
    margin: 10px 0;
  }
  img {
    margin: auto;
    border: 1px solid $graylight;
    border-left: 2px solid $graylight;
  }
}
.qualities {
  background: rgba($graylight,.28);
  padding: 5em 0;
  h2 {
    font-size: 2.3em;
    margin-bottom: 1em;
    display: inline-block;
  }
  h3 {
    font-size: 2em;
  }
  .text-pic {
    align-items: start;
    &>div {
      width: calc(50%);
      box-sizing: border-box;
      position: relative;
      p {
        font-size: 1.6em;
      }
    }
    .left {
      padding: 0;
    }
    .right {
      max-height: 320px;
      overflow: auto;
      padding: 0 20px 0 40px;
      margin-right: 20px;
    }
  }
  .slide.act {
    .item {
      &+.item {
        margin-top: 3em;
      }
    }
    .left {
      width: 260px;
    }
    .right {
      width: calc(100% - 260px);
    }
  }
}
.close_slick {
  float: right;
  font-size: 1.6em;
  cursor: pointer;
  &.act {
    .icon {
      transform: rotate(90deg);
    }
  }
  .icon {
    transform: rotate(-90deg);
    display: inline-block;
    transition: all .2s;
    &+span {
      margin-left: 15px;
    }
  }
}

.window {
  .arrows {
    display: none;
  }
}

@media #{$mq__desktop--small} {
  .window {
    .arrows {
      display: block;
    }
  }
  .references {
    h2 {
      margin-bottom: 60px;
    }
  }
  .references.category {
    .slick-arrow {
      top: -31px;
    }
    .slick-arrow.slick-next {
      left: 0px;
    }
    .slick-arrow.slick-prev {
      left: 60px;
    }
  }
  .colors {
    position: relative;
    h2 {
      margin-bottom: 60px;
    }
    .slick-slider {
      position: initial;
    }
    .group-color {
      .slick-arrow {
        top: -72px!important;
      }
      .slick-arrow.slick-next {
        left: 25px;
      }
      .slick-arrow.slick-prev {
        left: 85px;
      }
    }
  }
  .qualities,
  .properties {
    h2 {
      margin-bottom: 60px;
    }
    .slick-arrow {
      top: -73px;
    }
    .slick-arrow.slick-next {
      left: 200px;
    }
    .slick-arrow.slick-prev {
      left: 250px;
    }
  }
}

@media #{$mq__tablet} {
  .qualities,
  .properties {
    .item {
      padding-right: 0;
    }
  }
  .window {
    .window--info {
      margin-bottom: 60px;
    }
    .slick-arrow {
      top: -20px;
    }
    .slick-arrow.slick-prev {
      left: 60px;
    }
    .slick-arrow.slick-next {
      left: 0px;
    }
    .text-pic.layout1 {
      padding-right: 0;
    }
    .text-pic {
      flex-direction: column;
      .left {
        width: 100%;
      }
      .left {
        padding-right: 0;
      }
    }
  }
}

@media #{$mq__phablet} {
  .std-element {
    position: relative;
  }
  .references.category .slick-arrow.slick-prev {
    left: auto;
    right: 0;
  }
  .references.category .slick-arrow.slick-next {
    left: auto;
    right: 50px;
  }
  .properties .slick-arrow,
  .qualities .slick-arrow {
    top: -75px;
  }
  .properties .slick-arrow.slick-next, .qualities .slick-arrow.slick-next {
     left: auto;
     right: 50px;
   }
  .properties .slick-arrow.slick-prev, .qualities .slick-arrow.slick-prev {
    left: auto;
    right: 0;
  }
  .references {
    h2 {
      margin-bottom: 20px;
    }
  }
  .qualities,
  .properties,
  .colors {
    position: relative;
    h2 {
    }
    .close_slick {
      position: absolute;
    }
  }
  .colors {
    .close_slick {
      top: 40px;
    }
    h2 {
      margin-bottom: 50px;
    }
    .group-color .slick-arrow {
      top: -63px !important;
    }
    .group-color .slick-arrow.slick-next {
      left: auto !important;
      right: 50px;
    }
    .group-color .slick-arrow.slick-prev {
      right: 0!important;
      left: auto!important;
    }
  }
  .window{
    .window--info {
      flex-direction: column;
    }
    .window--buttons {
      margin-top: 30px;
      padding: 0;
    }
    .text-pic .rihgt {
      width: 100%;
    }
  }
  .properties {
    .close_slick {
      top: 39px;
    }
    h2 {
      margin-bottom: 87px;
    }
  }
  .qualities {
    .close_slick {
      top: 87px;
    }
    h2 {
      margin-bottom: 85px;
    }
  }
  .std-element.full {
    margin-top: 30px;
  }
  .std-element{
    .cc-info {
      position: initial;
      margin-top: 0px;
      a {
        position: absolute;
        top: -36px;
        left: 20px;
      }
    }
  }
  .qualities {
    .text-pic {
      .right {
        width: 100%;
        padding: 0;
      }
    }
  }
  .all_products.colors .group-color .slick-arrow {
    top: -15px!important;
  }
}

@media #{$mq__phone} {
  .std-element .cc-info a {
    top: -40px;
  }
  .std-element.full {
    margin-top: 60px;
  }
}