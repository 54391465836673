.references {
  position: relative;
  .flex_top {
    order: 1;
  }
  .flex_middle {
    order: 2;
  }
  .flex_bottom {
    order: 3;
  }
  h2 {
    font-size: 2.3em;
    margin-bottom: 15px;
  }
  .image {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  &.all {
    background: rgba($graylight,.28);
    .bn-wrap_small {
      padding: 70px 0;
    }
    .header {
      position: relative;
      h2 {
        color: $green;
        font-size: 2.3em;
        position: absolute;
        right: 0;
        top: 75px;
      }
    }
    .text-pic {
      overflow: hidden;
      &>div {
        align-self: flex-end;
      }
      .btn {
        margin: 20px auto 40px auto;
      }
    }
    .slick-arrow {
      left: calc(50% + 100px);
      top: 100px;
      width: 45px;
      height: 45px;
      line-height: 40px;
      text-align: center;
      border: 2px solid;
      z-index: 1;
      &.slick-prev {
        right: inherit;
        left: calc(50% + 143px);
      }
    }
  }
  &.category {
    .item {
      &>div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        &>div {
          width: 100%;
        }
      }
      .h2 {
        margin: 0.5em 0 0.5em;
      }
      .btn {
        /*position: absolute;
        bottom: 0;*/
      }
    }
    .slick-arrow {
      top: 35%;
    }
    .slick-list {
      margin: 0 -40px;
      .slick-track {
        display: flex;
      }
      .slick-slide {
        margin: 0 40px;
        display: flex!important;
        height: auto;
        justify-content: center;
        position: relative;
      }
    }
    .info_img {
      right: -75px;
      left: initial;
    }
  }
  .list {
    .search {
      display: none!important;
    }
    .filter {
      font-size: 2em;
      margin: 1em 0;
      .select {
        position: relative;
        bottom: inherit;
        left: 10px;
        float: left;
        width: 281px;
        display: flex;
        align-items: center;
        .info_font {
          position: absolute !important;
          left: 103%;
          top: 11px;
          color: $gray;
          bottom: inherit;
        }
        &+.select {
          margin-left: 50px;
          display: none;
        }
        &.act~.select {
          display: none!important;
        }
        &.act+.select {
          display: inline-block!important;
        }
      }
      select {
        width: 280px;
        height: 44px;
        padding: 5px;
      }
    }
    .flex_bottom {
      margin-bottom: 50px;
      /*padding-top: 80%;*/
      position: relative;
      &>div {
        /*position: absolute;
        top: 0;
        bottom: 0;*/
      }
    }
    .clearfix {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: start;
      margin: auto -25px;
      &.filter {
        margin: 2em -10px;
      }
    }
    .item {
      flex: 1 1 calc(100% / 3);
      max-width: calc(100% / 3);
      opacity: 1;
      transition: opacity .2s;
      &.hide {
        opacity: 0;
      }
      &>div {
        width: calc(100% - 50px);
        margin: auto;
      }
      .h2 {
        margin: 1em 0 0;
      }
    }
  }
}
.references .mb3 {
  margin-bottom: 4em;
}

.category.references {
  .mt3 {
    margin-top: 5em;
  }
}

@media #{$mq__tablet} {
  .references .list .item {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

@media #{$mq__phablet} {
  .references .list .item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}