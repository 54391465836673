.job {
  padding-top: 6em;
  padding-bottom: 6em;

  h2.h1 {
    font-size: 4em;
    text-transform: uppercase;
  }
  .date {
    font-size: 4em;
  }
  .bodytext {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    transition: max-height .2s;
    .item {
      width: 45%;
    }
    ul {
      margin-top: 1em;
      margin-left: 2em;
    }
    li:before {
      content: "-";
      left: -20px;
    }
    &:not(.act) {
      max-height: 238px!important;
    }
  }
  .btn {
    cursor: pointer;
    margin-top: 40px;
  }
  span.btn {
    margin-right: 20px;
  }
  a.btn {
    background: $green;
    color: $white;
    border-color: $green;
    &:hover {
      background: $white;
      color: $gray;
      border-color: $graylight;
    }
  }
  &.odd {
    background: rgba($graylight,.28);
  }
}

@media #{$mq__phone} {
  .job {
    .bodytext {
      width: 100%;
      flex-direction: column;
      .item {
        width: 100%;
      }
    }
  }
}