.bn_search {
  background-color: rgba($white,.8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  display: block;
  z-index: -1;
  .close {
    font-size: 15px;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    transition-duration: .5s;
    &:hover {
      color: $green;
    }
  }
  .box {
    background-color: $white;
    height: auto;
    width: 735px;
    min-width: 260px;
    display: inline-block;
    overflow: hidden;
    position: absolute;
    top: 60%;
    transform: translate(0%, 100%);
    left: 0;
    right: 0;
    margin: auto;
    font-size: 2em;
    box-shadow: 0 1px 14px 0 rgba(0,0,0,.16);
    &>div {
      padding: 75px 120px;
    }
    .headline {
      font-size: 23px;
      display: block;
      color: $green;
      text-align: center;
      margin-bottom: 15px;
    }
    .searchfield {
      padding-bottom: 59px;
    }
    #ke_search_searchfield_sword {
      font-size: 18px;
      height: 65px;
      width: 66.7%;
      padding: 0 15px;
    }
    .submit {
      display: inline-block;
      float: right;
      width: 33.3%;
      height: 65px;
      font-size: 18px;
      color: $green;
      background: $graylight;
      border: 1px solid #aaaaaa;
      cursor: pointer;
    }
  }
  .linkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
      font-size: 18px;
      border: 1px solid $graylight;
      line-height: 35px;
      padding: 0 20px;
      margin: 8px 12px;
      min-width: 110px;
      text-align: center;
      color: $gray;
      &:hover {
        background: $graylight;
      }
    }
  }
  &.act {
    z-index: 99999;
    .box {
      transform: translate(0%, -50%);
      transition-duration: .5s;
      top: 50%;
    }
  }
}

@media #{$mq__laptop} {
  .bn_search.act .box {
    width: 100%;
  }
  .bn_search .box>div {
    padding: 70px 20px;
  }
}
@media #{$mq__phone} {
  .bn_search .close {
    top: 75px;
  }
}