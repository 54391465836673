/*  ==========================================================================
    MIXINS
    Collection of all mixins (mind the ordering!)
    ========================================================================== */

@import 'lib/general';

@keyframes pulsate {
  0% {
    width: 25px;
  }
  50% {
    width: 40px;
  }
  100% {
    width: 25px;
  }
}
@keyframes overflow {
  to {
    overflow:hidden;
  }
}