/*  ==========================================================================
    COLORS
    List all colors concerning your project here
    ========================================================================== */

$white: #FFF;
$black: #000;
$green: #00663a;
$gray: #4D4D4D;
$darkgray: #292929;
$graylight: #cccccc;
$graymiddle: #707070;