.accessories {
  h3 {
    font-size: 3em;
    color: $green;
    line-height: 1;
    padding: .5em 0 1em 0;
  }
  h4 {
    font-size: 2.5em;
    color: $green;
    line-height: 35px;
    margin: .5em 0;
  }
  .toggle-box {
    padding: 0;
  }
  .accessor {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    &>div {
      /*width: calc(100% / 3 - 30px);*/
      /*width: 260px;*/
      width: 33.3%;
      margin-bottom: 5em;
      /*margin-left: 40px;*/
      /*&:nth-child(1) {
        margin-left: 0px;
      }*/
    }
  }
}
.toggle.accessories {
  margin: 10px auto !important;
}

@media #{$mq__max-w--800} {
  .accessories .accessor>div {
    width: 50%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media #{$mq__max-w--600} {
  .accessories .accessor>div {
    width: 100%;
  }
}
@media #{$mq__phone} {
  .toggle {
    .h3 {
      width: calc(100% - 30px);
    }
    .icon {
      width: 50px;
      > div:before {
        display: none;
      }
    }
  }
}