.cc-filter {
  display: flex;
  flex-direction: column;
  font-size: 1.8em;
  position: relative;
  .filter-top {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    > span {
      color: #2F653D;
      margin-right: 20px;
    }
    .cur-items {
      display: flex;
      flex-wrap: wrap;
      .cur-selection {
        padding-right: 35px;
        position: relative;
        cursor: pointer;
        height: 40px;
        background-color: #EFEFEF;
        padding-left: 10px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }

    .delete-filter {
      padding: 0 20px;
      cursor: pointer;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #EDEDED;
      border: 2px solid #4D4D4D;
      &:hover {
        background-color: #fff;
      }
    }
  }
  .filter-bottom {
    display: flex;
    flex-wrap: wrap;
    .search {
      padding-left: 20px;
      width: 320px;
      height: 45px;
      border: 2px solid #4D4D4D;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .search-btn {
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 20px;
        padding-left: 10px;
        border-left: 2px solid #4D4D4D;
      }
      input {
        border: 0;
      }
    }
    .models,
    .material {
      position: relative;
      margin-bottom: 20px;
    }
    .dropbtn {
      margin-right: 90px;
      border: 2px solid #4D4D4D;
      width: 280px;
      height: 45px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      .icon-pfeil_unten {
        height: 100%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        border-left: 2px solid #4D4D4D;
        font-size: 0.7em;
      }
    }
    .dropdown-content {
      position: absolute;
      z-index: 100;
    }
    .filter-item {
      display: flex;
      align-items: center;
      padding-left: 20px;
      cursor: pointer;
      width: 280px;
      height: 45px;
      border: 2px solid #4D4D4D;
      border-top: 0;
      background-color: #F1F1F1;
      &:hover {
        background-color: #fff;
        color: #2F653D;
      }
      &--cur {
        display: none;
      }
    }
  }
  .dropdown-content {
    display: none;
    &--act {
      display: flex;
      flex-direction: column;
    }
  }
}
.windows,
.doors {
  /*.item {
    display: none;
    &--act {
    }
  }
  .item--sel.item--act {
    display: flex;
  }*/
  .close {
    position: absolute;
    right: 12px;
    top: 10px;
    width: 22px;
    height: 22px;
    opacity: .3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 22px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}

/*.item--act.item--sel.item-search {
  display: flex !important;
}
.item-search--not-found {
  display: none !important;
}*/

.item--act.item-search--act {
  display: flex !important;
}
.item-search--not-found {
  display: none !important;
}
.item-prev,
.item-next {
  font-size: 0.5em;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
    text-decoration: none !important;
    i {
      &:before {
        color: #00663a;
      }
    }
  }
}
.window,
.doors {
  > .h1 {
    display: flex;
  }
}
.no-element-found {
  bottom: -45px;
  z-index: -1;
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  &--act {
    display: flex;
    justify-content: center;
  }
}
@media #{$mq__max-w--1125} {
  .cc-filter .filter-bottom {
    .dropbtn {
      margin-right: 20px;
      width: 185px;
    }
    .search {
      width: 300px;
      input {
        width: 200px;
      }
    }
  }
}

@media #{$mq__phablet} {
  .cc-filter {
    .filter-bottom .material,
    .filter-bottom .models {
      width: 100%;
    }
    .filter-bottom .search,
    .filter-bottom .material,
    .filter-bottom .dropbtn {
      width: 100%;
    }
    .filter-bottom .dropdown-content {
      width: 100%;
    }
    .filter-bottom .filter-item {
      width: 100%;
    }
    .filter-bottom .search input {
      width: 107px;
    }
  }
  .filter.mt3 {
    margin-bottom: 4em;
  }
}

@media #{$mq__phone} {
  .cc-filter .filter-top {
    flex-direction: column;
  }
}