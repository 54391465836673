.timeline {
  min-height:100vh;
  margin: auto;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 125px;
    left: 50%;
    bottom: 170px;
    transform: translateX(-50%);
    width: 4px;
    background-color: $green;
  }
  .entries {
    width: 100%;
    margin: auto;
    position: relative;
    left:-5px;
    .entry {
      width:calc(50% - 30px);
      float:left;
      padding:20px;
      clear:both;
      text-align:right;
      display: flex;
      justify-content: space-between;
      &:not(:first-child) {
        margin-top:-60px;
      }
      .left {
        order: 1;
        width: calc(100% - 200px);
      }
      .right {
        order: 2;
        position: relative;
        height: 225px;
        width: 170px;
        &:before {
          content: "\e923";
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          top: 45%;
          transform: translateY(-50%);
          right: -38px;
          color: $green;
          font-family: icomoon;
          font-size: 1.5em;
        }
      }
      .year {
        font-size: 6em;
        position: relative;
        color: $green;
      }
      .title {
        font-size: 2.5em;
      }
      &:nth-child(2n) {
        text-align:left;
        float:right;
        .left {
          order: 2;
        }
        .right {
          order: 1;
          &:before {
            right: inherit;
            left: -70px;
          }
        }
        .title {
          &:before {
            left:-63px;
          }
          &.big:before {
            transform:translate(-8px,-50%);
          }
        }
      }
    }
    .image {
      overflow: hidden;
    }
    img {
      width: 100%;
      height: auto;
      transform: scale(1);
      transition: transform .5s;
      &:hover {
        cursor: zoom-in;
        transform: scale(1.1)!important;
      }
    }
    .info_font {
      bottom: 15px;
      left: 20px;
    }
    .info_img {
      left: 5px;
      bottom: 45px;
      text-align: center;
    }
  }
}

@media #{$mq__tablet} {
  .timeline {
    .entries {
      .entry {
        .left {
          width: 100%;
        }
        .right {
          position: initial;
        }
        .right:before {
          top: 122px;
          right: -18px;
        }
      }
      .entry:nth-child(1n) {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
      }
      .entry:nth-child(2n) {
        display: flex;
        flex-direction: column;
        .right:before {
          right: inherit;
          left: -49px;
        }
      }
    }
  }
}

@media #{$mq__phablet} {
  .timeline {
    &:before {
      left: 90%;
    }
    .entries {
      margin-bottom: 20px;
      .entry {
        width: 100%;
        padding-right: 90px;
        margin-bottom: 30px;
        .left {
        }
        .right {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .image {
            width: 170px;
          }
        }
        .right:before {
          left: 89%;
          right: unset;
        }
      }
      .entry:nth-child(1n) {
      }
      .entry:nth-child(2n) {
        text-align: right;
        float: left;
        .right:before {
          left: 89%;
        }
      }
    }
  }
}
@media #{$mq__phone} {
  .timeline {
    .entries {
      .entry {
        padding-right: 60px;
        padding-left: 0;
        .right:before {
          left: 88%;
        }
      }
      .entry:nth-child(2n) {
        .right:before {
          left: 88%;
        }
      }
    }
  }
}