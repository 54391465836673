/*  ==========================================================================
    BUTTONS
    ========================================================================== */

.std-element .btn {
  width: 235px;
  padding: 10px 0;
  line-height: 25px;
  border: 2px solid $graylight;
  display: inline-block;
  font-size: 1.8em;
  color: $gray;
  text-decoration: none;
  text-align: center;
  &:hover {
    background: $graylight;
    text-decoration: none;
  }
}
.image .btn{
  width: unset;
  line-height: 46px;
  border: 0;
  display: block;
  font-size: unset;
  color: transparent;
  text-decoration: none;
  text-align: unset;
  &:hover {
    background: transparent;
  }
}