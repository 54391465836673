/*  ==========================================================================
    STD GALLERY
    ========================================================================== */
.gallery1 {
  .slick-list {
    margin: 0 -45px;
    .slick-slide {
      margin: 0 15px;
    }
  }
  .item {
    position: relative;
    overflow: hidden;
    width: 33%;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    &.lazyloaded {
      transition: transform .5s;
    }
    &:hover {
      cursor: zoom-in;
      transform: scale(1.1)!important;
    }
  }
}

.gallery2 {
  .btn {
    margin: auto;
    display: block;
    margin-top: 50px;
    cursor: pointer;
    border-color: $graylight;
    transition-duration: .2s;
  }
  .grid-sizer {
    width: 33.33%;
  }
  .bn-gallery__frame {
    margin: 0 -5px;
    position: relative;
  }
  .bn-gallery__image {
    cursor: zoom-in;
    float: left;
    width: 33.33%;
    position: relative;
    transition-duration: 0s;
    border: 5px solid $white;
    box-sizing: border-box;
    > div {
      overflow: hidden;
    }
    img {
      display: block;
      height: auto;
      width: 100%;
      &:hover {
        transform: scale(1.1)!important;
      }
    }
    .lazyload, .lazyloading {
      opacity: 0;
    }
    .lazyload--progressive {
      opacity: 1;
      filter: blur(30px);
      transform: scale(1.1);
      transition: transform .5s;
    }
    .lazyloaded {
      opacity: 1;
      transition: transform .5s, opacity .5s
    }
    .lazyload--progressive.lazyloaded {
      animation: blureffect .5s both;
      filter: blur(20px);
      transform: scale(1);
      @keyframes blureffect {
        0% {filter: blur(20px);}
        100% {filter: blur(0);}
      }
    }
    @media only screen and (max-width: 1500px) {
      width: 50%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.full_img {
  max-width: 1370px;
  img {
    width: 100%;
    height: auto;
  }
}


.cc-gallery {

  .bn-gallery__frame.clearfix {
    display: flex;
    flex-flow: row wrap;
    position: relative;
  }
  .bn-gallery__image {
    margin: 0 5px 5px;
    flex: 0 1 calc((100%/6) - 10px);
  }
  .aside {
    float: left;
    display: inline;
    width: 300px;
  }
  .element {
    float: left;
    display: inline;
    font-weight: 400;
    font-size: 14px;
    width: calc(100% - 300px);
  }
  h3 {
    display: inline-block;
    cursor: pointer;
    font-size: 1.8em;
  }
  img {
    width: 100%;
    height: auto;
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

@media #{$mq__phone} {
  .full_img {
    width: 100%;
  }
}