.all_products {
  h2 {
    margin-bottom: 25px;
  }
  .item {
    display: flex;
    background-color: #f3f3f3;
    /*background: rgba($graylight,.28);*/
    margin: 20px 0;
    padding: 30px 20px;
    box-sizing: border-box;
    &>div {
      width: 100%;
    }
  }
  .left {
    min-width: 53%;
    padding-right: 10px;
  }
  .sel_cat {
    font-size: 2em;
    position: relative;
    span {
      color: $green;
    }
  }
  .bodytext {
    p {
      font-size: 1.6em;
      line-height: 25px;
    }
  }
  .image {
    display: inline-block;
    position: relative;
    &+.image {
      margin-left: 3%;
    }
  }
  .bottom_flex {
    display: flex;
    &>div {
      width: 100%;
    }
    .sel_prop {
      margin-left: 37px;
      position: relative;
      max-width: 330px;
      b {
        font-size: 1.6em;
        margin-bottom: 1em;
        display: inline-block;
      }
      .btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 200px;
      }
      .slick-arrow.slick-prev {
        right: -32px;
      }
      .slick-arrow.slick-next {
        left: -35px;
      }
    }
    .cat {
      display: inline-block;
      &+.cat {
        margin-left: 10px;
      }
    }
    .image {
      width: 190px;
    }
  }
}
.beschattungen .bottom_flex .image {
  width: 100%;
}
.all_products.windows .bottom_flex .sel_prop {
  max-width: 259px !important;
  margin-left: 75px;
  .btn {
    margin-left: -35px !important;
  }
}

.all_products.beschattungen {
  .slick-track {
    margin: 0 !important;
  }
  .left {
    min-width: 40%;
    width: 40%;
  }
  .right {
    width: 60%;
  }
  .bottom_flex {
    .image {
      width: fit-content;
    }
    .sel_prop {
      b {
        margin-left: -35px;
      }
      margin-left: 70px;
      position: relative;
      max-width: 250px;
      .btn {
        left: -35px;
      }
    }
  }
  /*img {
    width: 250px;
    height: 250px;
    object-fit: cover;
  }*/
}
.all_products.windows .bottom_flex .sel_prop {
  .btn.btn-no-image {
    position: initial !important;
  }
}

.all_products.beschattungen,
.all_products.windows {
  .bodytext {
    position: relative;
    &:before {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      background: linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 25%,#f3f3f3);
      z-index: 5;
    }
  }
}

@media #{$mq__laptop} {
  .all_products.beschattungen {
    .item>div {
      width: 100%;
    }
    .left {
      min-width: 100%;
      width: 100%;
    }
  }
  .all_products {
    .left {
      margin-bottom: 15px;
    }
    .item {
      flex-direction: column;
    }
  }
  .doors {
    .item {
      flex-direction: row;
    }
  }
}

@media #{$mq__max-w--800} {
  .all_products {
    .item {
      padding: 30px 20px;
    }
    .bottom_flex {
      flex-direction: column;
      .sel_prop {
        margin-top: 68px;
        width: 180px;
        margin-left: 37px !important;
        .btn {
          bottom: 81px;
          left: -37px;
        }
      }
    }
    .left {
      .image {
        display: none;
      }
    }
  }
  .all_products.windows {
    .bottom_flex {
      .sel_prop {
        .btn {
          bottom: 50px !important;
          left: -2px;
        }
      }
    }
  }
}

@media #{$mq__phone} {
  .all_products {
    .bottom_flex {
      .sel_prop {
        margin-top: 13px;
        margin-bottom: 40px;
        width: 180px;
        .btn {
          bottom: -58px;
          left: -37px;
        }
      }
    }
  }
  .all_products.windows .bottom_flex .sel_prop {
    margin-top: 68px;
    margin-bottom: 0px;
  }
}

