.themebox {
  .image--quer {
    width: 100% !important;
  }
  .item {
    margin-bottom: 30px;
  }
  position: relative;
  h2 {
    font-size: 2.3em;
    margin-bottom: 15px;
  }
  .image {
    position: relative;
    overflow: hidden;
    width: 300px;
    img {
      width: 100%;
      height: auto;
    }
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      /*width: 33%;*/
      width: 400px;
      &>div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        &>div {
          width: 100%;
        }
      }
      .h2 {
        margin: 1em 0 0 0;
      }
      &:nth-child(1n+4){
        margin-top: 60px;
      }
    }
  }
  .btn {
    margin-top: 1em;
  }
  .slick-arrow {
    top: 35%;
  }
  .slick-list {
    margin: 0 -40px;
    .slick-track {
      display: flex;
    }
    .slick-slide {
      overflow: hidden;
      margin: 0 40px;
      display: flex!important;
      height: auto;
      justify-content: center;
      position: relative;
    }
  }
}

@media #{$mq__phone} {
  .themebox {
    .image {
      width: 100%;
      span {
        width: 100%;
      }
    }
    > div {
      .item {
        width: 100%;
        .h2 {
          margin: 0.2em 0 0;
        }
      }
    }
  }
}