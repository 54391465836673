.text-pic {
  display: flex!important;
  align-items: center;
  &.layout1 {
    padding-right: 78px;
  }
  .item {
    overflow: hidden;
  }
  .left {
    padding-right: 200px;
    width: calc(50% + 100px);
    box-sizing: border-box;
    .h1 {
      padding-bottom: 20px;
    }
    .btn {
      margin-top: 20px;
    }
  }
  .right {
    position: relative;
    width: calc(50% - 100px);
    .info {
      display: none;
      width: 160px;
      padding: 12px;
      font-size: 12px;
      color: $gray;
      background: $white;
      position: absolute;
      left: 20px;
      bottom: 60px;
      box-shadow: 0 1px 1px 0 rgba($black,.16);
      &>div {
        position: relative;
        &:after {
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 15px solid $white;
          content: "";
          position: absolute;
          bottom: -20px;
          left: 1px;
        }
      }
      &:hover {
        display: block;
      }
    }
    span {
      position: absolute;
      bottom: 30px;
      left: 40px;
      color: $white;
      font-size: 22px;
      line-height: 1;
      cursor: pointer;
      &:hover {
        &+.info {
          display: block;
        }
      }
    }
  }
  &.layout2 {
    /*padding-left: 78px;*/
    direction: rtl;
    &>div {
      direction: ltr;
    }
    .left {
      padding: 0 0 0 200px;
    }
  }
  img {
    height: auto;
    width: 100%;
    display: block;
  }
}

@media #{$mq__tablet} {
  .text-pic.layout2 .left {
    padding: 0 0 0 100px;
  }
}
@media #{$mq__phablet} {
  .text-pic {
    flex-flow: column;
    padding-left: 0 !important;
    padding: 0 !important;
    padding-right: 0 !important;
    .left {
      padding-left: 0 !important;
      padding: 0 !important;
      padding-right: 0 !important;
      width: 100%;
      margin-bottom: 30px;
    }
    .right {
      width: 100%;
    }
  }
}
