/*  ==========================================================================
    GENERAL
    Useful global helpers

    INFO:
    - use it with care, its not a trash can!
    - contributions for the kickstarter are welcome!
    ========================================================================== */

// clearfix
@mixin clearfix() {
  *zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// reset default button reset
@mixin resetHTMLButton() {
  appearance: none;
  background: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
}

// responsive image
@mixin responsiveImg() {
  display: block;
  height: auto;
  width: 100%;
}

// cover image
@mixin coverImg() {
  display: block;
  height: auto;
  left: 50%;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}

// cover image reset
@mixin coverImgReset() {
  display: initial;
  left: auto;
  max-height: 100%;
  max-width: 100%;
  min-height: 0;
  min-width: 0;
  position: static;
  top: auto;
  transform: translate(0, 0);
}

// create table
@mixin createTable() {
  display: table;
  > * {
    display: table-row;
    height: 100%;
    width: 100%;
    > * {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

// reset table
@mixin resetTable() {
  display: initial;
  > * {
    display: initial;
    height: auto;
    width: auto;
    > * {
      display: initial;
      vertical-align: initial;
    }
  }
}

.bn-wrap_tiny,
.bn-wrap_small,
.bn-wrap {
  width: 100%;
  box-sizing: border-box;
  margin: auto;
}
.bn-wrap_tiny {
  max-width: 1000px;
}
.bn-wrap_small {
  max-width: 1370px;
}
.bn-wrap {
  max-width: 1820px;
}

.mb2 {
  margin-bottom: 3em;
}
.mb3 {
  margin-bottom: 9em;
}

.mt2 {
  margin-top: 3em;
}
.mt3 {
  margin-top: 9em;
}

.table {
  display: table;
  width: 100%;
  height: 100%;
  &>div {
    display: table-row;
    width: 100%;
    height: 100%;
    &>div {
      display: table-cell;
      height: 100%;
      width: 100%;
      vertical-align: middle;
    }
  }
}
.cubic-bezier {
  img {
    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    transform: scale(1.05);
  }
  &.slick-slider {
    img {
      transform: scale(1.0);
    }
    .slider-track {
      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    }
    .slick-active img {
      transform: scale(1.05);
    }
  }
}

.info_img {
  display: none;
  width: 160px;
  padding: 12px;
  font-size: 12px;
  color: $gray;
  background: $white;
  position: absolute;
  left: 20px;
  bottom: 60px;
  box-shadow: 0 1px 1px 0 rgba($black,.16);
  &>div {
    position: relative;
    &:after {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid $white;
      content: "";
      position: absolute;
      bottom: -20px;
      left: 1px;
    }
  }
  &:hover {
    display: block;
  }
}
.info_font {
  position: absolute;
  bottom: 30px;
  left: 40px;
  color: $white;
  font-size: 22px;
  line-height: 1;
  cursor: pointer;
  &:hover {
    &+.info_img {
      display: block;
    }
  }
}

.spacing-top {
  margin-top: 40px;
}

.text__collapsible-trigger {
  display: none;
  text-transform: uppercase;
  font-size: 2em;
}

.bn-footer {
  a[title="Galerie"] {
    display: none;
  }
}
.aside_fix {
  div[data-scroll="z1177"],
  div[data-scroll="z320"] {
    display: none;
  }
}

.element.category {
  overflow: visible;
}
.element.category.references {
  overflow: hidden;
}

.frame-type-list {
  margin: 0 auto;
  padding: 0 30px;
}

/*body {
  &.no-font {
    * {
      color: transparent !important;
      text-shadow: none !important;
    }
  }
}*/

/*
GALLERY
*/
#opc-frame {
  width: 100%;
  border: 0;
  max-width: 1370px;
  margin: 0 auto;
  display: block;
}

@media #{$mq__tablet} {
  .mb2 {
    margin-bottom: 2em;
  }
  .mb3 {
    margin-bottom: 4em;
  }

  .mt2 {
    margin-top: 2em;
  }
  .mt3 {
    margin-top: 4em;
  }
}
@media #{$mq__max-w--600} {
  .text__collapsible-trigger {
    display: block;
  }
  .text--collapsible {
    .bodytext {
      height: 200px!important;
      overflow: hidden;
      position: relative;
      &:before {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 25%,#fff);
        z-index: 5;
      }
    }
  }
}
@media #{$mq__phablet} {
  .mt2 {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .mt3 {
    margin-top: 4em;
    margin-bottom: 1em;
  }
}
.themebox--wrap,
.accessories-wrap {
  overflow: initial !important;
}
.js-image-zoom__zoomed-image {
  z-index: 100;
  /*width: 800px !important;
  height: 500px !important;*/
}
.img-container-models {
  img {
    /*width: 100%;
    height: 100%;*/
    object-fit: cover;
  }
}
.img-container {
  img {
    width: 100% !important;
    height: auto !important;
  }
}
.themebox .image {
  overflow: initial !important;
}

@media #{$mq__tablet} {
  .image--quer {
    .img-container-models {
      width: 100% !important;
      height: 250px !important;
      > img {
        width: 100% !important;
        height: 250px !important;
      }
    }
  }
}

.window-aside {
  width: 64px;
  .dna-aside-slider>div {
    margin-bottom: 0 !important;
  }
  .dna-aside-slider>div {
    padding: 0 !important;
  }
  .slick-slide {
    > div {
      border-bottom: 3px solid #ffffff !important;
    }
  }
  .slick-vertical .slick-slide {
    border: none !important;
  }
  .aside-button {
    position: relative;
    top: 0%;
    transform: translateX(25%);
  }
  .slick-track .aside_fix--act {
    background-color: rgb(78, 78, 78)!important;
  }
}

.aside-item--hide {
  opacity: 1 !important;
  background-color: #4a4a4a;
  pointer-events: none;
  cursor: none;
}

.aside-item {
  cursor: pointer;
}