.beschattung {
  .cc-back {
    top: -70px;
  }
  .text-pic {
    align-items: start;
  }
  .rihgt {
    width: calc(50% - 100px);
  }
}
.modells {
  h2 {
    font-size: 2.3em;
    margin-bottom: 1em;
    display: inline-block;
  }
  h3 {
    font-size: 1.6em;
    font-weight: normal;
    text-align: center;
    margin-top: 1em;
  }
  .bodytext.slide {
    display: flex;
  }
  .item {
    position: relative;
    display: inline-block;
    margin: 0 32.5px;
    max-width: 140px;
    &:first-child {
      margin-left: 0;
    }
  }
}

@media #{$mq__desktop--small} {
  .aside_fix-beschattungen {
    display: none;
  }
}

@media #{$mq__phablet} {
  .properties .slide.act .item {
    width: 100%;
  }
  .beschattung {
    .rihgt {
      width: 100%;
      display: flex;
      justify-content: center;
      > div {
        width: 70%;
      }
    }
  }
}

@media #{$mq__phone} {
  .beschattung {
    .slick-arrow.slick-next {
      left: -35px;
    }
    .slick-arrow.slick-prev {
      right: -35px;
    }
  }
}