/*  ==========================================================================
    FOOTER
    Styling for the lowest part of the website
    ========================================================================== */

.bn-footer {
  padding: 0 20px;
  background: rgba($graylight,.28);
  margin: 5em auto 10em auto;
  font-weight: 100;
  line-height: 1.2;
  h2 {
    font-weight: bold;
  }
  .subheader {
    font-size: 2em;
    line-height: 1.2;
    margin-top: 11px;
    display: inline-block;
    color: rgba($gray,.72);
    &:hover {
      color: $green;
    }
  }
  a {
    color: $gray;
    text-decoration: none;
    &:hover {
      color: $green;
    }
  }
  .footer-box {
    display: flex;
    padding: 80px 0 50px 0;
    h3 {
      color: $green;
      margin: 50px 0 40px 0;
    }
    p {
      margin: 1.2em 0;
      line-height: 2;
    }
    .info {
      p {
        font-size: 1.6em;
        color: rgba($gray,.72);
        a {
          color: $green;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .row-wrap {
      display: none;
    }
    .row {
      font-size: 1.6em;
      p {
        line-height: 1.3;
      }
    }
    .social {
      margin-top: 6em;
      .icon {
        font-size: 22px;
        &:hover {
          &:before {
            color: $green;
          }
        }
        &:before {
          color: $graymiddle;
          transition-duration: .5s;
        }
      }
      a {
        &+a {
          margin-left: 25px;
        }
      }
    }
    &>div {
      width: 100%;
      &:first-child {
        width: 340%;
      }
    }
  }
  .footer-legal {
    display: flex;
    padding-bottom: 35px;
    font-size: 1.6em;
    color: rgba($gray,.72);
    a {
      color: rgba($gray,.72);
      &:hover {
        color: $green;
      }
    }
    &>div {
      width: 100%;
    }
    .bn-legal-menu {
      text-align: center;
      a + a {
        margin-left: 80px;
      }
    }
    .bn_link {
      text-align: right;
      a {
        color: $black;
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

@media #{$mq__tablet} {
  .bn-footer {
    padding: 0;
    .footer-box {
      flex-wrap: wrap;
      padding: 30px 0 30px;
      .social {
        margin-top: 1em;
      }
      p {
        padding-top: 10px;
        margin: 0.4em 0;
        text-align: center;
      }
      h3 {
        margin: 30px 0 20px;
      }
      .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: left;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .footer-box>div {
      width: 25%;
    }
    .footer-box>div:first-child {
    }
    width: 100%;
    .footer-legal {
      background-color: #fff;
      padding-top: 25px;
      flex-wrap: wrap;
      .bn-legal-menu a+a {
        margin-left: 7px;
      }
      .bn_link {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .bn-legal-menu {
        width: 55%;
        display: flex;
        justify-content: flex-start;
      }
      .bn-legal-menu {
        a {
          display: flex;
        }
        a:nth-child(1) {
          &:before {
            content: ' | ';
            display: block;
            padding-right: 5px;
          }
        }
        a:after {
          content: ' | ';
          display: block;
          padding-left: 5px;
        }
      }
    }
    .bn_link {
      a {
        &:after {
          display: none;
        }
        &:before {
          display: none;
        }
      }

    }
    .footer-legal>div {
      width: 45%;
      display: flex;
      justify-content: flex-end;
      padding-right: 7px;
    }
  }
}

@media #{$mq__phablet} {
  .bn-footer {
    .footer-box {
      p {
        margin: 0;
        padding: 0;
      }
      h3 {
        margin: 10px 0 10px;
      }
      .row {
        display: none;
      }
      .row-wrap {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        font-size: 1.7em;
        width: 100%;
        align-items: center;
        .row--mobile {
          .row-headline {
            display: flex;
            align-items: center;
            .icon-pfeil_unten {
              padding-left: 10px;
              padding-right: 10px;
              &--act {
                transform: rotate(180deg);
              }
              &:before {
                color: #2F653D;
                font-size: 0.7em;
              }
            }
          }
          .row-content {
            display: none;
            &--act {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media #{$mq__phone} {
  .bn-footer {
    .footer-legal>div {
      width: 100%;
      justify-content: center;
    }
    .footer-legal .bn-legal-menu {
      width: 100%;
      justify-content: center;
    }
  }
}
