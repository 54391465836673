.index-search {
  margin-top: 30px;
  &__search {
    display: flex;
    margin-bottom: 30px;
  }
  .tx-indexedsearch-searchbox-sword {
    height: 30px;
    width: 200px;
    border: 1px solid #bbbbbb;
    font-size: 15px;
    padding-left: 10px;
    color: #2b2b2b;
    border-radius: 2px;
  }
  .tx-indexedsearch-searchbox-button {
    cursor: pointer;
    height: 100%;
    margin-left: 10px;
    color: #2b2b2b;
    border-radius: 2px;
    border: 1px solid #bbbbbb;
  }
}
ul.tx-indexedsearch-browsebox {
  display: flex;
  list-style-type: none;
  margin-bottom: 30px;
  flex-wrap: wrap;
  li {
    padding-right: 10px;
  }
  a {
    text-decoration: none;
    font-size: 13px;
  }
}
.tx-indexedsearch-percent,
.tx-indexedsearch-info {
  display: none !important;
}
.tx-indexedsearch-res {
  margin-bottom: 20px;
}