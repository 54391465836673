.linkbox {
  .linkbox-wrap__mobile {
    display: none;
  }
  display: flex;
  .item {
    text-align: center;
    background: rgba($graylight,.28);
    justify-content: space-between;
    padding: 50px 28px;
    width: 100%;
    position: relative;
    &+.item {
      margin-left: 15px;
    }
    &>div {
      margin-bottom: 50px;
    }
    .icon {
      font-size: 8.8em;
      margin-bottom: 30px;
      display: inline-block;
    }
    .bodytext {
      margin: 25px auto;
    }
    .btn {
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
.linkbox.element {
  overflow: visible  !important;
}
@media #{$mq__laptop} {
  .linkbox {
    width: 100%;
    position: relative;
    .item {
      /*width: 25%;*/
      .h2 {
        font-size: 2.5em;
      }
    }
  }
}

@media #{$mq__tablet} {
  .linkbox.element {
    margin-bottom: 8em;
  }
  .linkbox {
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 0;
      > div {
        margin-bottom: 0;
      }
      .btn {
        bottom: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        font-size: 1.2vw;
      }
      .bodytext {
        display: none;
      }
    }
  }
}

@media #{$mq__phablet} {
  .linkbox {
    flex-direction: column;
    .item+.item {
      margin-left: 0px;
    }
    .item {
      width: 100%;
      justify-content: flex-start;
      &:nth-last-child(1) {
        padding-bottom: 30px;
      }
      .h2 {
        position: absolute;
        right: 50%;
        transform: translateX(50%);
      }
      .btn {
        top: 50%;
        bottom: 20%;
        width: 200px;
        font-size: 1.2em;
      }
    }
    .item>div {
      display: flex;
    }
  }
  .linkbox-spacing {
    padding: 0 !important;
  }
}

@media #{$mq__phone} {
  .linkbox {
    .linkbox-wrap__mobile {
      right: 20px;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .btn {
        display: block !important;
        position: initial !important;
        font-size: 1.9em;
      }
      .h2 {
        display: block !important;
        position: initial !important;
        text-align: initial !important;
      }
    }

    .item {
      .icon {
        font-size: 6em;
      }
      .btn {
        right: 20px;
        left: unset;
        display: none;
        height: fit-content;
        font-size: 1.9em;
      }
      .h2 {
        right: 20px;
        transform: unset;
        top: 20px;
        display: none;
      }
    }
  }
}
@media #{$mq__phone} {
.linkbox {
  .item {
    font-size: 0.6em;
  }
}

}

