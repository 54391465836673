.berater {
  display: flex;
  align-items: flex-end;
  background: rgba($graylight,.28);
  padding: 55px 115px 65px 140px;
  .contact {
    font-size: 1.6em;
    margin-top: 25px;
    color: rgba($gray,.72);
  }
  .bodytext {
    p {
      margin-bottom: 0;
    }
    ul {
      margin-left: 0;
      li {
        margin: 1em 0;
      }
    }
  }
  .btn {
    margin-top: 25px;
  }
  img {
    border-radius: 260px;
    margin-right: 25px;
  }
  &>div {
    margin: 0 50px;
    width: 100%;
    position: relative;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      min-width: 285px;
      max-width: 285px;
      margin: auto 0 0 50px;
    }
  }
  &.layout1 {
    .h2 {
      margin-top: 60px;
    }
    .bodytext {
      margin-top: 25px;
    }
  }
  &.layout2 {
    padding: 55px 115px 65px 65px;
    .photo-mobile {
      display: none;
    }
    &>div {
      &.photo {
        width: 260px;
        margin: 0;
        &+div {
          padding-left: 30px;
        }
      }
    }
    .h2 {
      font-size: 24px;
      line-height: 1;
    }
  }
}

@media #{$mq__laptop} {
  .layout2 {
    .photo {
      display: none;
    }
    .photo-mobile {
      display: block;
    }
  }
  .berater.layout2 .photo-mobile {
    display: block;
  }
}

@media #{$mq__phablet} {
  .berater {
    flex-direction: column;
    > div {
      width: 100%;
      margin: 0;
    }
    > div:last-child {
      width: 100%;
      max-width: 100%;
      display: flex;
    }
  }
  .berater.layout2 {
    flex-direction: column;
    position: relative;
    padding: 30px 20px;
  }
  .berater {
    padding: 30px 40px;
    padding-right: 0 !important;
  }
}

@media #{$mq__max-w--600} {
  .berater {
    padding: 30px 0px;
    >div:first-child {
      padding-left: 50px;
      padding-right: 15px;
    }
  }
  .berater>div:last-child {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .berater img {
    width: 250px;
  }
}