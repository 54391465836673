.toggle {
  margin: 25px auto;
  .header {
    position: relative;
    border-top: 2px solid rgba($gray,.7);
    border-bottom: 2px solid rgba($gray,.7);
    color: rgba($gray,.7);
    &.act {
      .icon {
        background: rgba($graylight,.28);
        span {
          transform: rotate(90deg);
        }
      }
    }
  }
  .h3 {
    width: calc(100% - 120px);
    padding: 8px 15px;
  }
  .icon {
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    line-height: 48px;
    width: 120px;
    font-size: 1.5em;
    transition: background .2s;
    cursor: pointer;
    &:hover {
      background: rgba($graylight,.28);
    }
    &>div {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 2px;
        background: rgba($gray,.7);
        top: 5px;
        bottom: 5px;
      }
    }
    span {
      transition: .2s;
      transform: rotate(-90deg);
      display: inline-block;
    }
  }
  .bodytext {
    p {
      margin: 0;
    }
  }
  .toggle-box {
    display: none;
    margin: 2em 0;
    padding: 0 15px;
  }
}