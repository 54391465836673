.door {
  .cc-back {
    top: -70px;
  }
  .text-pic.layout1 {
      display: flex;
      justify-content: space-between;
  }
  .text-pic {
    align-items: start;
    .rihgt {
      width: 400px;
    }
    .left {
      padding: 0;
      width: auto;
      /*padding-right: 400px;
      width: 100%;*/
    }
  }
}
.all_products.doors {
  .bottom_flex {
    height: 100%;
  }
  .image {
    img {
      padding-left: 50px;
      width: auto;
      height: 100%;
      box-sizing: border-box;
    }
  }
}
/*.doors .item {
  display: none !important;
  &--act {
    display: flex !important;
  }
}*/
/*.doors {
  .filter-bottom {
    display: flex;
  }
  .filter-item--cur {
    display: none;
  }
  .door-filter {
    position: relative;
    width: fit-content;
    display: none;
  }
  .dropbtn {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 2em;
    border: none;
    cursor: pointer;
    > div {
      width: fit-content;
      border: 2px solid #4d4d4d;
      padding: 0 5px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 160px;
      .icon-pfeil_unten {
        font-size: 0.6em;
        margin-left: 10px;
        transition: 0.2s;
      }
    }
    .bn-icon {
      text-decoration: none;
      margin-left: 20px;
    }
  }
  .door-filter:hover {
    .dropbtn {
      > div {
        .icon-pfeil_unten {
          transition: 0.2s;
          transform: rotate(180deg);
        }
      }
    }
    .dropdown-content {
      display: flex;
      flex-direction: column;
      font-size: 2em;
    }
  }
  .dropdown-content {
    min-width: 160px;
    padding: 5px 20px;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6);
    z-index: 1;
    cursor: pointer;
  }
  .filter-item,
  .filter-item--all {
    &:hover {
      text-decoration: underline;
    }
  }
}

.doors .door-filter {
  display: flex;
  flex-direction: column;
}*/

/*
Pop Up door
*/
.popup {
  transition: 1s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(#000, 0.9);
  z-index: 1000;
  .cross {
    position: relative;
    .close {
      cursor: pointer;
      position: absolute;
      left: 32px;
      top: 32px;
      width: 32px;
      height: 32px;
      color: #fff;
    }
    .close:hover {
      opacity: 0.5;
    }
    .close:before, .close:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #fff;
    }
    .close:before {
      transform: rotate(45deg);
    }
    .close:after {
      transform: rotate(-45deg);
    }
  }
  &__arrows {
    position: absolute;
    width: 100%;
    height: 100%;
    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 4em;
      cursor: pointer;
      &:before {
        color: #fff;
      }

      &:hover {
        &:before {
          opacity: 0.5;
        }
      }
    }
    .icon-arrow_left2 {
      left: 50px;
    }
    .icon-arrow_right2 {
      right: 50px;
    }
  }
  &__wrap {
    max-width: 800px;
    margin: 0 auto;
    height: 100%;
    display: flex;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    font-family: alwyn-new-web, sans-serif;
    font-size: 2em;
    h2 {
      color: #fff;
    }
    .content__top {
      margin: 25px 0px;
      span {
        font-weight: 700;
      }
    }
  }
  img {
    height: 100%;
    width: auto;
    padding: 20px 0;
  }
}

.popup-field {
  cursor: pointer;
}

@media #{$mq__desktop--large} {
  .popup {
    .popup__wrap {
      > a {
        height: 100%;
        width: 920px;
        position: relative;
        margin-right: 30px;
        img {
          overflow: hidden;
        }
      }
      max-width: 1030px;

    }
  }
}
@media #{$mq__laptop} {
  .popup {
    .cross {
      z-index: 200;
    }
    .popup__arrows {
      z-index: 100;
      .icon {
        top: 50px;
        font-size: 2.5em;
      }
      .icon-arrow_right2 {
        right: auto;
        left: 150px;
      }
      .icon-arrow_left2 {
        left: 100px;
      }
    }
    &__content {
      padding-left: 20px;
      font-size: 1.5em;
      padding-right: 10px;
    }
    &__wrap {
      justify-content: center;
      a {
        padding-left: 10px;
        display: flex;
        align-items: center;
        width: 330px;
        position: relative;
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

@media #{$mq__tablet} {
  .doors .item {
    flex-direction: row;
    position: relative;
  }
  .all_products.doors {
    .bottom_flex {
      .sel_prop {
        position: initial !important;
        .btn {
          position: absolute;
          bottom: 20px;
          left: 20px;
        }
      }
    }
  }
}

@media #{$mq__950} {
  .popup {
    overflow-y: scroll;
    .popup__content {
      width: 100%;
      max-width: 350px;
      .content__top {
        margin: 10px 0;
      }
      .content__bottom {
        margin-bottom: 10px;
      }
    }
    .popup__wrap {
      padding-top: 75px;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      max-width: 800px;
      margin: 0 auto;
      min-height: 100%;
      display: flex;
      height: auto;
      a {
        padding: 0px 20px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 350px;
        position: relative;
      }
    }
  }
}
@media #{$mq__phablet} {
  .door {
    .text-pic .rihgt {
      width: 200px;
    }
  }
}
@media #{$mq__phone} {
  .all_products .item {
    flex-direction: column !important;
  }
}